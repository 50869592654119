import {DextJsonSchema} from '@wix/form-fields';
import React from 'react';
import {DataExtendedFieldsMeta} from '../../../types/app.types';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';

export type DataExtendedFieldValue = string | string[] | boolean | number;

type ExtendedFieldsValues = Record<string, DataExtendedFieldValue | undefined>;

export interface UserExtendedFieldsValues {
  namespaces: {
    _user_fields: ExtendedFieldsValues;
  };
}

export enum ExtendedFieldsViewHooks {
  Root = 'ExtendedFieldsView.Root',
  FieldText = 'ExtendedFieldsView.FieldText',
}

export interface DataExtendedFieldsSchema {
  additionalProperties: boolean;
  properties: DataExtendedFieldsMeta;
}

const ExtendedFieldView = ({value, meta}: {value?: DataExtendedFieldValue; meta?: DextJsonSchema}) => {
  const localeKeys = useLocaleKeys();

  /* istanbul ignore next */
  if (!meta) {
    return null;
  }

  const getDisplayValue = () => {
    /* istanbul ignore next */
    if (value === undefined) {
      return '';
    }

    switch (meta.type) {
      case 'array':
        return Array.isArray(value) ? value.join(', ') : /* istanbul ignore next */ value;
      case 'boolean':
        return value
          ? localeKeys.checkout.extended_fields_view.checkbox.true()
          : localeKeys.checkout.extended_fields_view.checkbox.false();
      default:
        return value;
    }
  };
  return (
    <div data-hook={ExtendedFieldsViewHooks.FieldText}>
      {meta.title}: {getDisplayValue()}
    </div>
  );
};

export const ExtendedFieldsView = ({
  values,
  meta,
}: {
  values: UserExtendedFieldsValues;
  meta: DataExtendedFieldsMeta;
}) => {
  const userFields = values.namespaces._user_fields;
  return (
    <div data-hook={ExtendedFieldsViewHooks.Root}>
      {Object.keys(userFields).map((target) => (
        <ExtendedFieldView meta={meta[target]} value={userFields[target]} key={target} />
      ))}
    </div>
  );
};
